import React, { } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import jaPinnenImage from "./images/JaPinnen.png";
import Typography from "@material-ui/core/Typography";
import withWidth from '@material-ui/core/withWidth';
import AcUnitIcon from "@material-ui/icons/AcUnit"
import tikkieSvg from "./images/Tikkie-Cropped.jpg";

const useStyles = makeStyles((theme) => ({

    centerImg: {
        display: "block",
        margin: 'auto',

    },
    imgContainerSizeSmall: {
        width: `clamp(20px, 100%, 25px)`,
        margin: theme.spacing(2),
    },
    imgContainerSizeMedium: {
        width: `clamp(70px, 100%, 90px)`,
        padding: theme.spacing(2),
    },
    imgContainerSizeLarge: {
        width: `clamp(40px, 100%, 240px)`,
    },

}))

const OpenHours = () => {
    const classes = useStyles();
    return (
        <div style={{ padding: "16px" }}>
            <Grid container justify="space-between" spacing={2} style={{ width: "250px" }}
            >
                <Grid item >
                    <Typography variant="h6" color="primary">
                        Openingstijden
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                style={{ textAlign: 'left', width: "250px", paddingTop: "16px" }}
            >
                <Grid item xs={4}>
                    <Typography variant="body2" color="primary" align="left">
                        Maandag
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" color="primary" align="right">
                        gesloten
                    </Typography>{" "}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2" color="primary" align="left">
                        Dinsdag
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" color="primary" align="right">
                        9.00 - 18.00
                    </Typography>{" "}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2" color="primary" align="left">
                        Woensdag
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" color="primary" align="right">
                        9.00 - 18.00
                    </Typography>{" "}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2" color="primary" align="left">
                        Donderdag
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" color="primary" align="right">
                        9.00 - 18.00
                    </Typography>{" "}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2" color="primary" align="left">
                        Vrijdag
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" color="primary" align="right">
                        9.00 - 18.00
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2" color="primary" align="left">
                        Zaterdag
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" color="primary" align="right">
                        8.00 - 16.00
                    </Typography>{" "}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2" color="primary" align="left">
                        Zondag
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" color="primary" align="right">
                        gesloten
                    </Typography>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: "16px", paddingBottom: "16px" }}>
                    <Grid item >
                        <AcUnitIcon color={"primary"} />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body2" color="primary" align="left" >
                            De salon is voorzien van airconditioning en luchtzuivering
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ paddingTop: "16px", paddingBottom: "24px" }}>
                    <img alt="jaPinnen" className={classes.imgContainerSizeMedium} src={jaPinnenImage} />
                    <img alt="tikkie" className={classes.imgContainerSizeMedium} src={tikkieSvg} />
                </Grid>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="primary">
                            Capelli kapper
                        </Typography>
                        <Typography variant="subtitle2" color="primary">
                            Molenstraat 176
                        </Typography>
                        <Typography variant="subtitle2" color="primary">
                            5701 KL Helmond
                        </Typography>
                        <Typography variant="subtitle2" color="primary">
                            0492 523360
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>

    )
}








export default withWidth()(OpenHours);