import { DialogContent } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Dialog from '@material-ui/core/Dialog';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/Phone";
import React, { createRef, useState } from 'react';
import useWayPointScrollSpy from "../src/utils/useWayPointScrollSpy";
import Footer from "./Footer";
import Hero from './Hero';
import logo from "./images/logo.jpg";
import OpenHours from "./OpenHours";
import Products from "./Products";
import Salon from './Salon';
import Section from './Section';
import WayPointScrollSpy from "./utils/WayPointScrollSpy";


const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        backgroundColor: theme.palette.secondary.main
    },

}))

//home aa
const Home = () => {
    const currentPoint = useWayPointScrollSpy((state) => state.wayPoints);
    const homeRef = createRef(null);
    const salonRef = createRef(null);
    const productenRef = createRef(null);
    const contactRef = createRef(null);
    const classes = useStyles();

    const [showWarning, setShowWarning] = useState(true);

    const theme = useTheme();

    return (
        <React.Fragment>
            {/* <Header /> */}
            <AppBar >
                <Grid container justify="center" alignItems="center">
                    <Grid item>
                        <img src={logo}
                            width={100}
                            alt="logo"
                            style={{ marginLeft: "16px", cursor: "pointer" }}
                            onClick={() => {
                                homeRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <Tabs value={currentPoint[0] ? currentPoint[0] : "Home"}>
                            <Tab value={"Home"} style={{ display: "none" }} />
                            <Tab label={"Salon"} value={"Salon"} onClick={() => {
                                salonRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                            }} />
                            <Tab label={"Producten"} value={"Producten"} onClick={() => {
                                productenRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                            }} />
                            {/* <Tab label={"Acties"} value={"Acties"} onClick={() => {
                                actiesRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                            }} /> */}
                            <Tab label={"Contact"} value={"Contact"} onClick={() => {
                                contactRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                            }} />
                        </Tabs>
                    </Grid>
                </Grid>
                <Dialog open={showWarning}
                    maxWidth={"lg"}
                    onClose={() => {
                        setShowWarning(false)
                    }}
                    onClick={() => {
                        setShowWarning(false)
                    }}
                    style={{
                        marginBottom: "100px",
                        cursor: "pointer",
                        textAlign: "center",
                    }}
                    PaperProps={{ classes: { root: classes.dialogPaper } }}
                >
                    <DialogContent>
                        <Grid container>
                            <Grid item>
                                <Typography color="secondary" variant="h3" style={{
                                    color: theme.palette.primary.main
                                }}>
                                   In de vakantieperiode zijn wij op de Dinsdagen gesloten ( 16, 23, 30 juli, en 6 en 13 augustus)
                                   </Typography>
                                <Typography color="secondary" variant="caption" style={{
                                    color: theme.palette.primary.main
                                }}>
                                    Klik om te sluiten
                                </Typography>
                            </Grid>

                        </Grid>
                    </DialogContent>
                </Dialog>
            </AppBar >
            <Toolbar />
            <WayPointScrollSpy section={"Home"}>
                <div ref={homeRef}>
                    <Hero appointmentAction={() => {
                        contactRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                    }} />
                </div>
            </WayPointScrollSpy>
            <Section title={"Salon"} disableGutters={true} maxWidth={false} scrollspy ref={salonRef}>
                <Salon />
            </Section>
            <Section title={"Producten"} disableGutters={true} maxWidth={false} scrollspy ref={productenRef}>
                <Products />
            </Section>
            {/* <Section title={"Acties"} disableGutters={true} maxWidth={"md"} scrollspy ref={actiesRef}>
                <ReferalProgram />
            </Section> */}
            <Section title={"Contact"} disableGutters={true} maxWidth={"md"} scrollspy ref={contactRef}>
                <Grid container justify="center" style={{ textAlign: "center" }}>
                    <Grid item>
                        <PhoneIcon color="primary" fontSize="large" />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" color={"primary"} style={{ marginTop: "16px", textAlign: "center" }} display={"inline"}>
                            Bel ons op  <Link color={"primary"} href="tel:0492-523360" >
                                0492-523360
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="center" spacing={2} style={{ textAlign: "center" }}>
                        <OpenHours />
                    </Grid>
                </Grid>
            </Section>
            <Footer />
        </React.Fragment >
    )
}
export default Home;