import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route component={Home} path="/" exact />
          <Route component={Home} path="/home" exact />
        </Switch>


        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}

      </Router>
    </div>
  );
}

export default App;
