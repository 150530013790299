import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import svg from "./svg/low-poly-grid-haikei.svg";
import { useTheme } from "@material-ui/core/styles";
import WayPointScrollSpy from "./utils/WayPointScrollSpy";
import WayPointTransition from "../src/utils/WayPointTransion";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#231F20",
        position: "relative",
        marginBottom: "-4px",
        width: "100%",
    },
    rotate180: {
        transform: "rotate(180deg)",
    },
    topRamp: {
        width: "100%",
    }
}))
const Section = React.forwardRef((props, ref) => {

    const { title, children } = props;

    const classes = useStyles();

    const theme = useTheme();

    return (
        <div className={classes.root} ref={ref}>

            <WayPointScrollSpy section={title} >
                <div className={classes.topRamp}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        className={classes.rotate180}
                    >
                        <path fill={`${theme.palette.primary.main}`} d="M0,96L1440,192L1440,320L0,320Z"></path>
                    </svg>
                </div>
                <WayPointTransition animateInOnEnter>
                    <Typography variant="h2" color={"primary"} style={{ margin: "16px" }}>
                        {title}
                    </Typography>
                    {children}
                </WayPointTransition>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                    style={{ backgroundImage: `url(${svg})` }}
                    className={classes.rotate180}
                >
                    <path fill="#231F20" d="M0,96L1440,192L1440,320L0,320Z"></path>
                </svg>
            </WayPointScrollSpy>
        </div>


    )
})

export default Section;