import React  from "react";
import { Waypoint } from 'react-waypoint';
import  useWayPointScrollSpy  from "./useWayPointScrollSpy";

const WayPointScrollSpy = (props) => {
    const wayPoints = useWayPointScrollSpy((state) => state.wayPoints);
    const setWayPoints = useWayPointScrollSpy((state) => state.setWayPoints);

    const { section } = props;

    const onEnter = () => {
        const newWayPoints = [...wayPoints];
        newWayPoints.push(section);
        setWayPoints(newWayPoints);

        if (props.onEnter) {
            props.onEnter();
        }
    }

    const onLeave = () => {
        const newWayPoints = [...wayPoints];

        const deleteIndex = newWayPoints.findIndex((wayPoint) => wayPoint === section);
        newWayPoints.splice(deleteIndex, 1);
        setWayPoints(newWayPoints);

        if (props.onLeave) {
            props.onLeave();
        }
    }

    return (
        <Waypoint
            onEnter={onEnter}
            onLeave={onLeave}
        >
            <div>
                {props.children}
            </div>
        </Waypoint>
    )
}
export default WayPointScrollSpy