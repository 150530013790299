import React from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import privacyPolicy from './files/privacyverklaringcapelli.pdf'
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
    },
    image: {
        padding: theme.spacing(4)
    },
    footer: {
        backgroundColor: theme.palette.primary.main,
    }

}))



const Footer = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                      
                    </Grid>
                </Grid>
            </Container>

            <Toolbar className={classes.footer}>
                <Grid container>
                    <Grid item  >
                        <Typography variant="subtitle2" style={{ color: "white" }}>
                            @{new Date().getFullYear()} capelli kapper
                        </Typography>
                    </Grid>
                    <Grid item xs style={{ textAlign: "end" }} >
                        <Link href={privacyPolicy} target='_blank' rel='noopener noreferrer' style={{ color: "white" }}>
                            Privacy verklaring
                        </Link>
                    </Grid>
                </Grid>
            </Toolbar>
        </React.Fragment>

    );
}

export default Footer;