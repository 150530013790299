import { Container } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from "react";
import SwiperCore, {
    Autoplay
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import sal1 from "./images/salon/20210617_185420.jpg";
import sal2 from "./images/salon/20210617_185619.jpg";
import sal3 from "./images/salon/20210617_190004.jpg";
import sal4 from "./images/salon/20210617_190031.jpg";
import sal5 from "./images/salon/20210617_190050.jpg";


SwiperCore.use([Autoplay]);


const useStyles = makeStyles((theme) => ({
    image: {
        padding: theme.spacing(4),
        width: "300px",
        height: "300px"
    },
    stepper: {
        background: theme.palette.secondary.main,
    }
}))

const Salon = () => {

    const onChange = value => {
        setStep(value);
    }




    const classes = useStyles();
    const theme = useTheme();

    const [step, setStep] = useState(0);

    const images = [sal1, sal2, sal3, sal4, sal5];


    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));


    let size = 3;

    if (mdDown) {
        size = 2;
    }

    if (smDown) {
        size = 1
    }


    const steps = images.length / size + 1;
    const rows = [];

    for (let i = 1; i < steps; i++) {
        const start = i * size - size;
        const end = i * size;
        rows.push(images.slice(start, end))
    }

  

    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <Container>
                    <Swiper
                        autoplay={{
                            "delay": 2500,
                            "disableOnInteraction": false
                        }}
                        centeredSlides={true}
                        plugins={[Autoplay]}
                        spaceBetween={10}
                        slidesPerView={size}
                        speed={2000}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={(slide) => onChange(slide.activeIndex)}
                    >
                        {


                            images.map((src, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <img alt="" key={index} className={classes.image} src={src} />
                                    </SwiperSlide>

                                )
                            })


                        }


                    </Swiper>
                </Container>
            </Grid>
            <MobileStepper
                className={classes.stepper}
                variant="dots"
                steps={images.length}
                activeStep={step}
                position="static"

            />

        </Grid>

    )
}

export default Salon;