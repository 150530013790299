import React, { useState } from "react";
import { Waypoint } from 'react-waypoint';
import Fade from "@material-ui/core/Fade";

 const WayPointTransition = (props) => {
    const [toggleAnimate, setToggleAnimate] = useState(false);
    const { animateOutOnLeave, animateInOnEnter } = props;

    const onEnter = () => {
        if (animateInOnEnter) {
            setToggleAnimate(true)
        }
    }

    const onLeave = ({ currentPosition }) => {
        if ( animateOutOnLeave) {
            setToggleAnimate(false)
        }
    }

    return (
        <Waypoint
            onEnter={onEnter}
            onLeave={onLeave}
            topOffset={"300px"}
            bottomOffset={"300px"}
        >
            <Fade in={toggleAnimate} timeout={1000}>
                <div>
                    {props.children}
                </div>
            </Fade>
        </Waypoint>
    )
};

export default WayPointTransition