import React, { } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import background from "./images/hero.jpg"
import logo from "./images/logo.jpg";
import Grid from "@material-ui/core/Grid";
import facebookPng from "./images/facebook.png";
import instaPng from "./images/insta.png";
import joicoPng from "./images/joicoLogo.png";
import award1Png from "./images/award1.png";
import award2Png from "./images/award2.png";
import WayPointTransition from "../src/utils/WayPointTransion";
import withWidth from '@material-ui/core/withWidth';
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
    root: {
        height: `calc( 100vh - ${theme.mixins.toolbar.minHeight}px)`,
        width: "100%",
        position: "relative",
        "&:before": {
            content: "''",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            opacity: 0.05,
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,

        }
    },
    logo: {
        marginTop: theme.spacing(16),
        width: `clamp(300px, 50%, 900px)`,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(4),
        },
    },
    joicoLogo: {
        width: `clamp(50px, 50%, 300px)`,

    },
    wave: {
        position: "absolute",
        bottom: "-20px",
        right: 0,
        zIndex: 1,
        height: "60vh",
        [theme.breakpoints.down('sm')]: {
            height: "100vh",
        },
    },
    infoContainer: {
        bottom: 0,
        position: "absolute",
        height: "100vh",
        [theme.breakpoints.down('sm')]: {
            position: "sticky",
        },
        zIndex: 2,
    },
    centerImg: {
        display: "block",
        margin: 'auto',

    },
    imgContainerSizeSmall: {
        width: `clamp(30px, 100%, 40px)`,
        margin: theme.spacing(2),
    },
    imgContainerSizeMedium: {
        width: `clamp(40px, 100%, 50px)`,
    },
    imgContainerSizeLarge: {
        width: `clamp(40px, 100%, 240px)`,
    },
    heroFooter: {
        position: "absolute",
        bottom: 0,
        zIndex: 1,
    },
    swipeContainer: {
        width: "100%",
        height: "50vh",
    },
    topRamp: {
        zIndex: 0,
        width: "100%",
    }
}))




const Hero = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { appointmentAction } = props;




    return (
        <WayPointTransition animateInOnEnter animateOutOnLeave>
            <div className={classes.root} ref={ref}>
                <div className={classes.topRamp}>
                    <svg
                        className={classes.wave}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                    >
                        <path fill="#231F20" d="M0,96L1440,192L1440,320L0,320Z"></path>
                    </svg>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 1440 320`} >
                        <path fill="white" fill-opacity="1" d="M0,64L1440,0L1440,320L0,320Z"></path>
                    </svg> */}
                </div>
                <Grid container justify="center" >
                    <Grid item xs={12}>
                        <img alt="logo" className={classes.logo} src={logo} ref={ref} />

                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={appointmentAction} variant={"contained"} color="primary" style={{ zIndex: 2 }}>
                            Afspraak maken
                        </Button>
                    </Grid>

                </Grid>


                <div className={classes.heroFooter}>
                    <Grid container alignItems="center" >
                        <Grid item xs={12}>

                            <img alt={"joico logo"} className={classes.joicoLogo} src={joicoPng} ref={ref} />
                        </Grid>
                        <Grid item xs={6} container justify="flex-start" >
                            <a  href={"https://www.instagram.com/capelli_kapper/"} className={classes.imgContainerSizeSmall} target="_blank" rel="noreferrer" >
                                <img alt={"insta logo"} className={classes.imgContainerSizeSmall} src={instaPng} />
                            </a>
                            <a  href={"https://www.facebook.com/capellikapper"} className={classes.imgContainerSizeSmall} target="_blank" rel="noreferrer" >
                                <img alt={"facebook logo"} className={classes.imgContainerSizeSmall} src={facebookPng} display={"inline"} />
                            </a>
                        </Grid>
                        <Grid item xs={6} container justify="flex-end" >
                            <img alt={"award 1"} className={classes.imgContainerSizeSmall} src={award1Png} display={"inline"} />
                            <img alt="award 2"  className={classes.imgContainerSizeSmall} src={award2Png} display={"inline"} />
                        </Grid>

                    </Grid>
                </div>

            </div>
        </WayPointTransition>

    )
})

export default withWidth()(Hero);