import { Container } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState } from "react";
import SwiperCore, {
    Autoplay
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import product1 from "./images/products/Kopie van Joico Logo+Tag Black.jpg";
import product2 from "./images/products/Kopie van Web Use - Small - JPG-JOICO Blonde Life & Blonde Life Violet Group Shot .jpg";
import product3 from "./images/products/STRUCTURE-Groupshot.png";
import product4 from "./images/products/STRUCTURE-Logo-Black.jpg.jpg";
import product5 from "./images/products/Web Use - Small - JPG-DefyDamage_4_Retail_group_rev_10.23.jpg";
import product6 from "./images/products/Web Use - Small - JPG-HydraSplash_group.jpg";
import product7 from "./images/products/Web Use - Small - JPG-JOICO K-Pak Collection Image with logo.jpg";
import product8 from "./images/products/Web Use - Small - JPG-JOICO K-Pak Color Therapy Groupshot.jpg";
import product9 from "./images/products/Web Use - Small - JPG-joicolor System Groupshot Tube 1.jpg";
import product10 from "./images/products/Web Use - Small - JPG-JoiFull_group.jpg";
import product11 from "./images/products/Web Use - Small - JPG-Moisture Recovery_Group.jpg";




SwiperCore.use([Autoplay]);

const useStyles = makeStyles((theme) => ({
    image: {
        padding: theme.spacing(4),
        width: "300px",
        height: "300px"
    },
    stepper: {
        background: theme.palette.secondary.main,
    }
}))

const Products = () => {

    const classes = useStyles();
    const theme = useTheme();

    const [step, setStep] = useState(0);
    const onChange = value => {
        setStep(value);
    }

    const images = [product2, product3, product4, product5, product6, product7, product8, product9, product10, product11];


    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));


    let size = 3;

    if (mdDown) {
        size = 2;
    }

    if (smDown) {
        size = 1
    }


    const steps = images.length / size + 1;
    const rows = [];

    for (let i = 1; i < steps; i++) {
        const start = i * size - size;
        const end = i * size;
        rows.push(images.slice(start, end))
    }

    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <Container>
                    <Swiper
                        autoplay={{
                            "delay": 2500,
                            "disableOnInteraction": false
                        }}
                        plugins={[Autoplay]}
                        spaceBetween={10}
                        slidesPerView={size}
                        speed={2000}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={(slide) => onChange(slide.activeIndex)}
                    >
                        {


                            images.map((src, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <img alt="" key={index} className={classes.image} src={src} />
                                    </SwiperSlide>

                                )
                            })


                        }


                    </Swiper>
                </Container>
            </Grid>

            <MobileStepper
                className={classes.stepper}
                variant="dots"
                steps={images.length}
                activeStep={step}
                position="static"

            />
        </Grid>

    )
}

export default Products;